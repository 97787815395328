exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-battleships-tsx": () => import("./../../../src/pages/battleships.tsx" /* webpackChunkName: "component---src-pages-battleships-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-hex-snake-tsx": () => import("./../../../src/pages/hex-snake.tsx" /* webpackChunkName: "component---src-pages-hex-snake-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-snake-tsx": () => import("./../../../src/pages/snake.tsx" /* webpackChunkName: "component---src-pages-snake-tsx" */),
  "component---src-pages-storybook-tsx": () => import("./../../../src/pages/storybook.tsx" /* webpackChunkName: "component---src-pages-storybook-tsx" */),
  "component---src-pages-test-js-tsx": () => import("./../../../src/pages/test-js.tsx" /* webpackChunkName: "component---src-pages-test-js-tsx" */)
}

