module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.0_gatsby@5.12.4_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"alexmund.uk","short_name":"alexmund.uk","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icons":[],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.4_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
